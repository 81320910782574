.about {
  z-index: 1;
  line-height: 2;
  margin-bottom: 18px;
}

.about-button {
  color: whitesmoke;
  background: rgba(255, 255, 255, 0.164);
  border: none;
  font-size: 15px;
  font-weight: bolder;
  padding: 10px 15px;
  border-radius: 4px;
}
  
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.about-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50vh;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  height: 75%;
  width: 75%;
  overflow: auto;
  padding: 48px;
}

.about-demo {
  margin: 10px auto;
  font-size: 14px;
  font-style: italic;
}

.modal-guts {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.about-modal h1 {
  position: relative;
  padding-top: 10px;
  padding: 2px;
  margin: 0 auto;
  color: rgb(52, 110, 98);
}

.close {
  text-decoration: none;
  color: #346e62;
  font-family: sans-serif;
  height: 20px;
  width: 20px;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  right: 20px;
  display: flex;
  justify-content: center;
  background: whitesmoke;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
}

.register-link {
  margin: 0;
  color: #414141;
  padding-left: 15px;
}

.register-link {
  margin: 0;
  color: #414141;
  padding-left: 15px;
  color: rgb(52, 110, 98);
  padding-bottom: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.about-icon {
  height: 40px;
  padding: 5px;
  display: none;
}

.about p b {
  color: rgb(52, 110, 98);
  text-align: left;
}

.about p {
  width: 80%;
  margin: 0 auto;
}

.about h5 {
  margin: 0 auto;
  padding-top: 25px;
  width: 90%;
  font-style: italic;
}

#about-h1 {
  font-size: 32px;
  padding-top: 20px;
}

#question-0 {
  padding-bottom: 25px;
}

#question-1 {
  padding-bottom: 25px;
}

#question-2 {
  padding-bottom: 15px;
}

#question-3 {
  padding-bottom: 25px;
}

