.claim-plate {
  display: flex;
  flex-direction: column;
  background: inherit;
}

.claim-plate h2{
  padding-top: 10px;
  margin: 0 auto;
  color: rgb(52, 110, 98);
}

.claim-plate-search {
  margin: auto auto;
  width: 75%;
}

/* Fieldset ID */
#claim-plate-search {
  padding: 10px;
  border: 1px solid whitesmoke;
  margin-top: 20px;
}

.claim-plate-search-inputs {
  display: flex;
  justify-content: center;
}

/* plate number search input */
#claim-search {
  width: 30%;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  color: black;
}

.search-btn {
  padding: 8px 16px;
  font-size: 14px;
  margin: 8px auto;
  color: whitesmoke;
  background: rgb(52, 110, 98);
  box-shadow: 0 1px 1px 0 rgba(19, 18, 18, 0.3);
  border: none;
  border-radius: 6px;
}

.plate-table {
  margin: 0 auto;
  padding: 5px;
}

select.browser-default {
  font-size: 12px;
  /* text-align-last:center;  */
  /* width: 100px; */
  max-width: 35%;
  height: 44px;
  border-radius: 0px;
  background: white;
  /* margin-left: 8px; */
  background: whitesmoke;
  border: none;
  color: black;
  font-size: 15px;
  color: rgb(52, 110, 98);
  width: 100%;
  border: 1px solid #e4e6e8;
}

button.register-plate {
  color: rgb(52, 110, 98); 
  margin: 0 auto;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  margin-top: 20px;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 10;
  padding: 10px;
  border: 0;
  font: inherit;
  vertical-align: bottom;
}

tr th {
  text-align: center;
}

th {
  color: rgb(3, 65, 53);
  font-size: 14px;
  letter-spacing: 1.5px;
  /* white-space: nowrap; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.623);
}

td {
  font-size: 14px;
  
}

tr th:nth-child(1) {
  width: 30%;
}

tr th:nth-child(2) {
  width: 30%;
}

tr th:nth-child(3) {
  width: 30%;
}

tr th:nth-child(4) {
  width: 30%;
}

tr th:nth-child(5) {
  width: 30%;
}

th, td {
  margin: 30px;
}

@media only screen and (max-width: 479px) {
  .mobile-hide{ 
    display: none !important; 
  }
  /* th {
    margin: 0 auto;
  } */
}