.isClaimed-icon {
  width: 20px;
}

.review-list {
  list-style-type: none;
  padding: 15px;
  max-width: 100%;
  list-style: none;
  margin: auto auto;
}

.review-item {
  border-radius: 8px;
  margin: 15px auto;
  padding: 24px 28px;
  width: 300px;
  background: rgb(250, 250, 250);
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
  text-align: start;
}

.review-header {
  display: flex;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  gap: 12px;
}

.review-item h1 {
  font-size: 18px;
}

.search-section {
  display: flex;
}

.plate-btn {
  color: rgb(52, 110, 98);
  font-size: 24px;
  font-family: LicensePlate;
  border: none;
  padding: 0;
  margin-right: 8px;
  background: none;
}

/* for public-plate.js reviews */
.plate-name {
  padding: 4px;
  padding-top: 9px;
  background: white;;
  color: rgb(52, 110, 98);
  box-shadow: 0 1px 1px 0 rgba(19, 18, 18, 0.3);
  border-radius: 5px;
  font-size: 32px;
  font-family: LicensePlate;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  margin: 2px;
  margin-right: 12px;
}

button[disabled] { 
  pointer-events: none;
}

.owner-comment {
  color: darkslategrey;
  text-align: left;
}

/* on my-reviews.js component */
#owner-response-btn {
  padding: 8px;
  font-size: 14px;
  margin: auto auto;
  color: whitesmoke;
  background: rgb(52, 110, 98);
  box-shadow: 0 1px 1px 0 rgba(19, 18, 18, 0.3);
  border: none;
  border-radius: 4px;
  margin-top: 8px;
}

.submit-response {
  margin-top: 8px;
  text-align: center;
}

#owner-submit-form {
  border: none;
}

#owner-response {
  width: 100%;
  margin: 6px auto;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  border: none;
}

#submit-owner-response-btn {
  padding: 8px;
  font-size: 14px;
  margin: auto auto;
  color: black;
  background: lightgrey;
  box-shadow: 0 1px 1px 0 rgba(19, 18, 18, 0.3);
  border: none;
}

#review-search {
  border: none;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.search-label {
  width: 100%;
}

#search-form {
  display: flex;
  width: 100%;
}

.input-wrapper {
  width: 100%;
}

#search {
  width: 65%;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  color: black;
}

#search::placeholder {
  color: grey;
  font-size: 16px;
}

#review-time {
  margin: 0px;
}

#search-state {
  max-width: 35%;
  height: 44px;
  border-radius: 0px;
  background: white;
  /* margin-left: 8px; */
  background: whitesmoke;
  border: none;
  color: black;
  font-size: 15px;
  color: rgb(52, 110, 98);
  width: 100%;
  border: 1px solid #e4e6e8;
}

.spinner {
  width: 200px;
}

.review-container {
  margin: 0 auto;
}

.review-date {
  color: rgb(80, 80, 80);
  font-size: 12px;
  text-align: center;
}

.elapsed-time {
  font-size: 12px;
  font-style: italic;
  margin: 0px;
}

.review-title {
  align-items: center;
  display: flex;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.message {
  font-size: 20px;
  color: rgb(52, 110, 98);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}