/* .plates-back-link  {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  text-decoration: none
} */

.plates-back-link :hover {
  color: grey;
}

.public-plate {
  text-align: center;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding-top: 28px;
  background:grey;
  color: white;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.45);
  width: 75%;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.wrapper-positive {
  background-image: linear-gradient(to right bottom, rgb(189, 207, 140),#348663);
}

.wrapper-negative {
  background-image: linear-gradient(to right bottom, #8B0000, #550000);
}

.wrapper-neutral {
  background: linear-gradient(to right bottom, #cccccc, #333333)
}

.plate-info p {
  margin: 2px
}

.plate-div {
  display: flex;
  flex-direction: column;
}
