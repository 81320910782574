/* stylesheet for my-plate.js and public-plate.js */

.license-plate {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.plate-title {
  border: grey 1px solid;
  background: whitesmoke;
  border-radius: 3px;
  min-width: 124px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.45);
  font-family: LicensePlate;
  color: rgb(52, 110, 98);
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 14px;
}

.plate-title h2 {
  font-weight: lighter;
  margin: 13px;
  font-size: 53px;
  padding-top: 4px;
}

.plate-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 400px;
  margin: 28px auto;
}

.unclaim-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #414141;
}

#unregister-plate-btn {
  background: none;
  color: rgb(52, 110, 98);
  font-weight: 500;
  border: none;
  font-size: 16px;
  margin: 4px;
  margin-bottom: 8px;
  padding: 10px;
}

.buttons-div {
  display: flex;
  justify-content: space-evenly;
}

.confirm-buttons {
  background: #f4f4f4;
  border: none;
  color: inherit;
  font-size: 16px;
  margin: 4px;
}

.confirm-buttons:hover {
  color: grey;
}
