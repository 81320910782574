h1 {
    margin: 0;
    font-size: 32px;
    color: #414141;
  }

  .dashboard-nav {
    align-content: space-around;
    text-align: center;
  }
  
  .main-sections {
    display: flex;
    margin: 0;
    list-style: none;
    justify-content: center;
    border-top: 1px solid rgba(19, 18, 18, 0.1);
    border-bottom: 1px solid rgba(19, 18, 18, 0.1);
  }

  .main-sections li {
    display: flex;
    padding: 20px 0;
    text-align: center;
  }

  .main-sections li:hover {
    background-color:  rgba(19, 18, 18, 0.1);
  }

  .claim-plate, .my-plates {
    border-right: 1px solid #e7e7e7;
  }
  
  .search {
    padding-left: 15px;
    justify-content: flex-end;
    flex-direction: flex-end;
  }

  .header-logo {
    display: flex;
    margin: 0 auto;
    padding: 10px;
    list-style: none;
  }

  .header-logo:hover {
    cursor: pointer;
  }

  /* for non dashboard pages */
  .smaller-header-logo {
    display: flex;
    margin: -16px auto;
    padding: 10px;
    list-style: none;
  }

  .header-middle-area {
    margin-bottom: 10px;
  }

  ul a {
    text-decoration: none;
    margin: 5px;
  }

  .icon {
    width: 30px;
    margin: auto auto;
    padding: 2px;
  }

  .logo-wrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  
  nav {
    color: #fff;
    background-color: transparent;
    width: 100%;
    border: none;
    box-shadow: none;
    text-decoration: none;
  }
  
  nav ul a {
    color: #414141;
    text-decoration: none;
    margin: auto 12px;
    font-size: 14px;
    word-spacing: 4px;
    letter-spacing: 1px;
  }