.my-reviews-content {
  text-align: center;
  max-width: 400px;
  margin: auto auto;
}

.my-reviews h2 {
  padding-top: 10px;
  margin: 0 auto;
  color: rgb(52, 110, 98);
}

.my-reviews-back-link {
  text-align: left;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}


.my-reviews-back-link:hover {
  color: white;
}