@font-face {
  font-family: LicensePlate;
  font-style: lighter;
  src: local('License Plate'),
    url('./LicensePlate.ttf'); 
}

* {
  outline: 0;
}

body {
  font-family: 
    "rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F4F4;
  list-style: none;
  margin: auto auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  text-align: center;
  box-sizing: border-box;
  max-width: 100%;
  flex-wrap: wrap;
}

input {
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  max-width: 100%;
}

input:hover {
  border-color: rgb(52, 110, 98);
}

button {
  border: none;
}

button:hover {
  /* background-color: palevioletred; */
  color: #414141;
  cursor: pointer;
}

img {
  border-style: none;
  max-width: 100%;
}

legend {
  color: #414141;
  font-size: 16px;
}

table {
  width: 100%;
}

td {
  text-align: center;
}

th {
  text-align: center;
}

a {
  color: #414141;
}