.home {
  text-align: center;
  background: #f4f4f4;
  width: 100vw;
}

.landing-top {
  background:  linear-gradient(
    rgba(14, 141, 113, 0.5), 
    rgba(0, 0, 0, 0.6)
  ), url('../../assets/fog.jpg');
  background-size: 100% 100%;
  background-blend-mode: luminosity;
}

.landing-content {
  height: 74vh;
  align-items: center;
  display: flex;
  color: #414141;
  box-shadow: 0px 2px 6px 1px rgb(54, 60, 61, 0.1);
}

.landing-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.landing-text h1 {
  font-size: 52px;
  color: rgb(52, 110, 98);
}

#responsibly {
  color: rgba(255, 255, 255, 0.897);
  margin-top: 50px;
  margin-bottom: 0;
  width: 100%;
}

#anonymous {
  margin-top: 0;
  color: rgba(255, 255, 255, 0.897);
}

#catch-phrase {
  margin-top: 0px;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 1px;
  color: #f4f4f4d7;
}