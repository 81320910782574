.submit-review {
  width: 90%;
  max-width: 350px;
  margin: auto auto;
  padding: 20px 20px;
  justify-content: left;
}

#submit-review-form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  background: rgb(250, 250, 250);
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
}

#submit-review-form input {
  width: 35%;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  color: black;
}

.submit-review h3 {
  color: rgb(52, 110, 98);
}

button.review-submit {
  padding: 8px;
  font-size: 14px;
  margin: 8px auto;
  color: whitesmoke;
  background: rgb(52, 110, 98);
  box-shadow: 0 1px 1px 0 rgba(19, 18, 18, 0.3);
  border: none;
  border-radius: 3px;
}

.review-label {
  padding: 4px;
  margin-top: 8px;
  display: block;
}

select {
  color: rgb(52, 110, 98);
  width: 100%;
  border: 1px solid #e4e6e8;
}

textarea#review-message-input {
  height: 100px;
  width: 85%;
  font-size: 14px;
  resize: none;
  border: 1px solid #e4e6e8;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px;
}

.review-submit {
  font-size: 12px;
}

.state-select {
  font-size: 14px;
  /* text-align-last: center; */
  width: 100px;
}

.browser-default {
  font-size: 14px;
  /* text-align-last: center; */
  width: 100px;
}